/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NavBar from './NavBar';
import Footer from './Footer';
import Seo from './Seo';
import styles from './Page.module.scss';
import '../../styles/index.scss';

const Page = ({ children, title, description, navInverted, bgImage, containerWide }) => {
  const [isSticky, updateIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      try {
        const scrollTop = Math.abs(document.body.getBoundingClientRect().top);
        updateIsSticky(scrollTop >= 50);
      } catch (error) { console.log(error) }
    };
    
    if (window) window.addEventListener('scroll', handleScroll);

    return () => {
      if (window) window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <div className={classnames(bgImage ? styles.bgImage : undefined)}>
      <Seo title={title} description={description} />
      <NavBar inverted={navInverted} sticky={isSticky} />
      <div className={classnames(styles.mainContent, containerWide ? 'containerWide' : 'container')}>{children}</div>
      <Footer />
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
