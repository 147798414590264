import { Link } from 'gatsby';
import React from 'react';
import Hamburger from 'react-hamburgers';
import classnames from 'classnames';
import Button from '../common/Button';
import { ReactComponent as Logo } from '../../images/logo.svg';
import { ReactComponent as LogoW } from '../../images/logo-w.svg';
import { ReactComponent as IconUser } from '../../images/icons/user.svg';
import { loginUrl, signUpUrl } from '../../config/settings';
import styles from './NavBar.module.scss';

const menuItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Explore',
    path: '/explore',
  },
  {
    title: 'Pricing',
    path: '/pricing',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
  {
    title: 'Company',
    path: '/company',
  },
  {
    title: 'FAQ',
    path: '/faq',
  },
];

const Header = ({ inverted, sticky }) => {
  const logoImg = inverted ? <LogoW /> : <Logo />;
  const menuLogoImg = <Logo />;
  const [mobileMenuVisible, toggleMobileMenu] = React.useState(false);

  const hideMenu = React.useCallback(() => {
    toggleMobileMenu(false);
  }, []);

  const toggleHandler = () => {
    toggleMobileMenu(!mobileMenuVisible);
  };

  React.useEffect(() => {
    try {
      const root = document.getElementsByTagName('html')[0];
      if (mobileMenuVisible) {
        root.classList.add('mobileMenuVisible');
        document.body.classList.add('mobileMenuVisible');
      } else {
        root.classList.remove('mobileMenuVisible');
        document.body.classList.remove('mobileMenuVisible');
      }
    } catch (error) {
      console.log(error);
    }
  }, [mobileMenuVisible]);

  return (
    <header
      className={classnames(
        styles.headerContainer,
        inverted ? styles.inverted : undefined,
        sticky ? styles.sticky : undefined,
      )}
    >
      <div className="container">
        <div className={styles.headerContent}>
          <div className={styles.logoContainer}>
            <Link to="/">{logoImg}</Link>
          </div>
          <nav className={classnames(styles.topNav, mobileMenuVisible && styles.menuContainerVisible)}>
            <div className={styles.menuLogo}>
              <Link to="/">{menuLogoImg}</Link>
            </div>
            <ul className={classnames(styles.menuContainer, mobileMenuVisible && styles.menuContainerVisible)}>
              {menuItems.map(({ path, title }) => (
                <li key={title} className={styles.menuItem}>
                  <Link onClick={hideMenu} to={path} activeClassName={styles.activeLink}>
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
            <ul className={classnames(styles.accMenuContainer)}>
              <li className={styles.accMenuItem}>
                <Link to={loginUrl}>
                  <IconUser className={styles.icon} width={14} /> <span>Login</span>
                </Link>
              </li>
              <li className={classnames(styles.accMenuItem, styles.accMenuButton)}>
                <a href={signUpUrl}>
                  <Button color="danger">Sign Up</Button>
                </a>
              </li>
            </ul>
          </nav>
          <div className={classnames(styles.toggler, inverted && 'inverted')}>
            <Hamburger active={mobileMenuVisible} type="squeeze" onClick={() => toggleHandler()} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
