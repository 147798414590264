/* eslint-disable prettier/prettier */
import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import Button from '../common/Button';
import { ReactComponent as LogoW } from '../../images/logo-w.svg';
import { ReactComponent as IconArrBtn2 } from '../../images/icons/arr-btn-2.svg';
import { ReactComponent as IconVisa } from '../../images/icons/visa.svg';
import { ReactComponent as IconMC } from '../../images/icons/mastercard.svg';
import { ReactComponent as IconPaypal } from '../../images/icons/paypal.svg';
import { facebookUrl, instagramUrl, linkedinUrl, twitterUrl, contactEmail } from '../../config/settings';
import styles from './Footer.module.scss';

const footerMenuItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Explore',
    path: '/explore',
  },
  {
    title: 'Pricing',
    path: '/pricing',
  },
  {
    title: 'Contact',
    path: '/contact',
  },
  {
    title: 'Company',
    path: '/company',
  },
  {
    title: 'FAQ',
    path: '/faq',
  },
];
const socialMenuItems = [
  {
    title: 'Facebook',
    path: facebookUrl,
  },
  {
    title: 'Instagram',
    path: instagramUrl,
  },
  {
    title: 'Linkedin',
    path: linkedinUrl,
  },
  {
    title: 'Twitter',
    path: twitterUrl,
  },
].filter(({ path }) => !!path);
const otherMenuItems = [
  {
    title: 'GDPR',
    path: '/',
  },
  {
    title: 'Privacy Policy',
    path: '/privacy',
  },
  {
    title: 'Terms & Conditions',
    path: '/terms',
  },
];

const Footer = () => {
  const [subscriptionResult, setSubscriptionResult] = React.useState();
  const handleSubscribeFormSubmit = React.useCallback((e) => {
    e.preventDefault();
    e.persist();
    const email = e.target.email.value;
    addToMailchimp(email).then((r) => {
      setSubscriptionResult(r);
      if (r.result === 'success') e.target.reset();
    });
  }, []);
  const year = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.footerTop}>
          <div className={styles.footerTopContainer}>
            <div className={styles.footerLeft}>
              <div className={styles.logoContainer}>
                <Link to="/">
                  <LogoW height={25} />
                </Link>
              </div>
              <div className={styles.emailContainer}>
                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
              </div>
              <div className={styles.footerPayment}>
                <IconVisa width={32} />
                <IconMC width={25} />
                <IconPaypal width={50} />
              </div>
            </div>
            <nav className={styles.footerNav}>
              <div className={styles.footerNavCol}>
                <div className={styles.colHeading}>Links</div>
                <ul className={styles.menuContainer}>
                  {footerMenuItems.map(({ path, title }) => (
                    <li key={title} className={styles.menuItem}>
                      <Link to={path} activeClassName={styles.activeLink}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.footerNavCol}>
                {socialMenuItems.length ? <div className={styles.colHeading}>Follow us</div> : null}
                <ul className={styles.menuContainer}>
                  {socialMenuItems.map(({ path, title }) => (
                    <li key={title} className={styles.menuItem}>
                      <a href={path}>{title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.footerNavCol}>
                <div className={styles.colHeading}>Other</div>
                <ul className={styles.menuContainer}>
                  {otherMenuItems.map(({ path, title }) => (
                    <li key={title} className={styles.menuItem}>
                      <Link to={path} activeClassName={styles.activeLink}>
                        {title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </nav>
            <div className={styles.footerRight}>
              <div className={styles.footerSubscribe}>
                {subscriptionResult?.result !== 'success' ? (
                  <>
                    <div className={styles.blockHeading}>Subscribe Now!</div>
                    <div className={styles.formContainer}>
                      <form onSubmit={handleSubscribeFormSubmit}>
                        <input type="email" name="email" id="email" placeholder="Enter email address" required />
                        <Button color="primary" type="submit">
                          Subscribe
                        </Button>
                      </form>
                    </div>
                  </>
                ) : null}
                {subscriptionResult ? (
                  <div
                    className={classnames(
                      {
                        [styles.subscriptionResultError]: subscriptionResult.result === 'error',
                      },
                      styles.subscriptionResult,
                    )}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: subscriptionResult.msg }}
                  />
                ) : null}
              </div>
              <div className={styles.controls}>
                <Link to="/contact">
                  <Button color="inverted">
                    <span>Get in touch</span> <IconArrBtn2 className={styles.icon} width={8} />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.footerBottom}>
          <div className={styles.footerBottomContainer}>
            <div className={styles.footerCopy}>Boost &copy; {year}. All rights reserved</div>

            <nav className={styles.footerBottomNav}>
              <ul className={classnames(styles.menuContainer)}>
                {otherMenuItems.map(({ path, title }) => (
                  <li key={title} className={styles.menuItem}>
                    <Link to={path} activeClassName={styles.activeLink}>
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
