import React from 'react';
import ReactLoading from 'react-loading';
import theme from '../../config/theme';
import { fullscreen } from './Loading.module.scss';

export const Loader = ({ height = 50, width = 50 }) => {
  return <ReactLoading type="spin" color={theme.primaryColor} height={height} width={width} />;
};

const FullscreenLoading = () => (
  <div className={fullscreen}>
    <Loader />
  </div>
);

export default FullscreenLoading;
