export const mainAppUrl = process.env.GATSBY_MAIN_APP_URL;
export const signUpUrl = `${mainAppUrl}/sign-up`;
export const loginUrl = `${mainAppUrl}/login`;

export const createPlanCtaUrl = ({ plan = 'free', period = 'month' }) =>
  `${signUpUrl}/?pricing_plan=${plan}&period=${period}`;

export const facebookUrl = process.env.GATSBY_FACEBOOK_URL;
export const instagramUrl = process.env.GATSBY_INSTAGRAM_URL;
export const linkedinUrl = process.env.GATSBY_LINKEDIN_URL;
export const twitterUrl = process.env.GATSBY_TWITTER_URL;

export const contactEmail = process.env.GATSBY_CONTACT_EMAIL;

export const contactAddress = process.env.GATSBY_CONTACT_ADDRESS;
export const contactPhone = process.env.GATSBY_CONTACT_PHONE;
export const contactPhoneLink = process.env.GATSBY_CONTACT_PHONE_LINK;
export const contactOnlineServiceUrl = process.env.GATSBY_CONTACT_ONLINE_SERVICE_URL;
export const contactOnlineServiceEmail = process.env.GATSBY_CONTACT_ONLINE_SERVICE_EMAIL;
export const recaptchaSitekey = process.env.GATSBY_RECAPTCHA_SITEKEY;
export const demoWidgetServerUrl = process.env.GATSBY_DEMO_WIDGET_SERVER_URL;

export default {
  mainAppUrl,
  signUpUrl,
  loginUrl,
  facebookUrl,
  instagramUrl,
  linkedinUrl,
  twitterUrl,
  contactEmail,
  contactAddress,
  contactPhone,
  contactPhoneLink,
  contactOnlineServiceUrl,
  contactOnlineServiceEmail,
  recaptchaSitekey,
  createPlanCtaUrl,
  demoWidgetServerUrl,
};
